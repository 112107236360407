import { Header } from "./components/Header/Header";
import { Home } from "./pages/Home/Home";
import { Cursor } from "./shared/components/Cursor/Cursor";
import { Routes, Route } from "react-router-dom";
import { Layout } from "./pages/Layout/Layout";
import SEO from './shared/components/SEO/SEO';

function App() {
	return (
		<div className="app">
			<SEO/>
			<Header />
			
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/*" element={<Layout />} />
			</Routes>

			<Cursor />
		</div>
	);
}

export default App;
