import clsx from 'clsx';
import classes from './Price.module.scss';
import { formatPrice } from '../../services/format-price';

type PriceProps = {
    price: number,
    discountPercent?: number
}

export const Price = (props: PriceProps) => {
    const { price, discountPercent } = props;

    return (
        <>
            {
                !!discountPercent && (
                    <span className={clsx(
                        classes.price,
                    )}>
                        {`-${discountPercent}%`}
                    </span>
                )
            }
            <span className={clsx(
                classes.price, {
                [classes.striked]: !!discountPercent
            }
            )}>
                {formatPrice(price)}
                {" "}руб
            </span>

            {!!discountPercent && (
                <>
                    <span className={clsx(
                        classes.price,
                        classes.salePrice
                    )}>
                        {formatPrice(Math.floor(price * (100 - discountPercent) / 100))}
                        <span className={classes.ruble}>руб</span>
                    </span>
                </>
            )}
        </>
    );
}