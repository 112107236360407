import { Helmet } from 'react-helmet-async';

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  type?: string;
  url?: string;
}

const defaultMetadata = {
  siteName: 'man I trust ®',
  defaultTitle: 'man I trust ®',
  defaultDescription: 'Created in 2023, Man I Trust is a fashion and culture multi-brand store based in Moscow.',
  defaultImage: '/link-logo-grey.png',
  siteUrl: 'https://manitrust.ru',
};

function SEO({
  title,
  description,
  image,
  type = 'website',
  url,
}: SEOProps) {
  const metaTitle = title || defaultMetadata.defaultTitle;
  const metaDescription = description || defaultMetadata.defaultDescription;
  const metaImage = image || `${defaultMetadata.siteUrl}${defaultMetadata.defaultImage}`;
  const metaUrl = url || defaultMetadata.siteUrl;

  return (
    <Helmet>
      {/* General tags */}
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />

      {/* OpenGraph tags */}
      <meta property="og:site_name" content={defaultMetadata.siteName} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaImage} />
      <meta property="og:image:width" content="800" />
      <meta property="og:image:height" content="800" />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={metaUrl} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={metaImage} />
    </Helmet>
  );
}

export default SEO;